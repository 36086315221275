var uyapParser = {

    div:function (data) {
        var div = document.createElement("div");
        div.innerHTML = data;
        return div;
    },
    tahsilatReddiyat : function (data) {
        var div = document.createElement('div');
        div.innerHTML = data;
        var jsonData = {};
        var blueHoki = div.querySelectorAll('.blue-hoki');
        blueHoki.forEach(function(item,key){
            var dom = uyapParser.baslikParse(item.querySelector('.caption').innerText);
            var tablo = item.querySelectorAll('table');
            var basliklar = tablo[0].querySelectorAll('thead tr th');
            var icerikler = tablo[0].querySelectorAll('tbody tr');
            var objectBaslik = [];
            var objectIcerik = [];
            var icerik = {};
            basliklar.forEach(function(baslik,k){
                if(baslik.innerText != 'Detaylar')
                    objectBaslik.push(baslik.innerText);
                if(icerikler.length == 0){
                    icerik[uyapParser.baslikParse(baslik.innerText)]="";//"Kayıt bulunamadı";
                }
            });
            if(icerikler.length == 0)
                objectIcerik.push(icerik);

            icerikler.forEach(function(icerik,k){
                var item = {};
                icerik.querySelectorAll('td').forEach(function(td,v){
                    //console.log(objectBaslik[v]);
                    if(objectBaslik[v]!= undefined)
                        item[uyapParser.baslikParse(objectBaslik[v])]=td.innerText;
                });
                objectIcerik.push(item);
            });

            //console.log("icerikler",icerikler);
            //icer = icerikler;
            jsonData[dom]={
                "basliklar":objectBaslik,
                "icerik":objectIcerik
            };
        });
        return jsonData;
    },

    borclular  :{
        get:function (data) {
            //console.log('uyaptan gelen data');
            //uyapData = data;
            //console.log('parserdata',data);
            if(typeof data.message !="undefined")
                return data;
            if(data.indexOf('Dosyaya kayıtlı boçlu bilgisi bulunmamaktadır.')>=0){
                return {message:"Dosyaya kayıtlı borçlu bilgisi bulunmamaktadır"};
            }

            return uyapParser.borclular.baslik(uyapParser.tabloIcerikAl(data));
        },
        baslik: function (arr){
            resultset=[];
            //console.log(arr);
            for(var i = 0; i < arr.length; i++){
                /**
                 *onclick="DosyaBilgileriGoruntuleme.borcluBilgileriSet('36147192','İSMAİL','YÜCEDAĞ','0')"
                 * split 0 dan onlydıgıtle kısı kurum ıd alındı .. 23.03.2019
                 * emre
                 * eski kod  .. onlyDigit(arr[i][6].querySelector('a').attributes.onclick.value)
                 *
                 * @type {never|string[]}
                 */
                //console.log(uyapParser.tableHeadBodyToJson());
                if(arr[i].length == 3){ //kurumsa
                    var kisiKurumId = arr[i][2].querySelector('a').attributes.onclick.value.split("',");
                    kisiKurumId = onlyDigit(kisiKurumId[0]);
                    resultset.push({
                        "kurumAdi": arr[i][0].innerText,
                        "adi": arr[i][0].innerText,
                        "soyadi":"",
                        "babaAdi":"",
                        "anaAdi":"",
                        "dogumTarihi":"",
                        "tcKimlikNo":arr[i][1].innerText,
                        "vergiNo": arr[i][1].innerText,
                        "kisikurumid": kisiKurumId,
                    });
                }else{
                    var kisiKurumId = arr[i][6].querySelector('a').attributes.onclick.value.split("',");
                    kisiKurumId = onlyDigit(kisiKurumId[0]);
                    resultset.push({
                        "tcKimlikNo": arr[i][0].innerText,
                        "adi": arr[i][1].innerText,
                        "soyadi": arr[i][2].innerText,
                        "babaAdi": arr[i][3].innerText,
                        "anaAdi": arr[i][4].innerText,
                        "dogumTarihi": arr[i][5].innerText,
                        "kisikurumid":kisiKurumId,
                    });
                }
            }
            return resultset;
        }
    },
    dosyaDetay :{
        get: function (data) {
            return uyapParser.borcluAl(data);
        }
    },
    tarafDetay :{

        get:function (data) {

            //console.log(data);
            //tarafdetay ve adresler icin table icerik al a gidiyor basliklari koyulup json a ceviriliyor --emre
            tarafDetay = uyapParser.icerikAl(data);

            adresler = uyapParser.normalTabloParse(uyapParser.domTabloAl(data,1));

            return {tarafDetay:tarafDetay,adresler:adresler};
        }
    },
    dosyaTaraflari:function(data){
        return uyapParser.tableHeadBodyToJson(data);
    },
    findTableHeaders:function(headers){
        let obj = {};
        let basliklar = [];
        for(var i = 0; i < headers.length ; i++){
            obj[uyapParser.baslikParse(headers[i].innerText)] = "";
            basliklar.push(headers[i].innerText);
        }
        return {obj:obj,basliklar:basliklar};
    },
    tableHeadBodyToJson:function(data){
        //data = uyapParser.tabloAl(data);
        let div = document.createElement('div');
        div.innerHTML = data;
        let result=[];
        let basliklar=[];
        let headers = div.querySelectorAll('table thead th');
        let tr = div.querySelectorAll('table tbody tr');
        for(var i = 0; i < tr.length ; i++){
            var td = tr[i].childNodes;
            let tableHeaders = uyapParser.findTableHeaders(headers);
            let obj = tableHeaders.obj;
            basliklar = tableHeaders.basliklar;
            for(var j = 0; j < td.length; j++){
                obj[Object.keys(obj)[j]] = td[j].innerText.trim();
            }
            //console.log("obj",obj);
            result.push(obj);
            //console.log(result);
        }
        return {basliklar:basliklar,data:result};
    },
    mernis:{

        get:function (data) {

            var ddiv = document.createElement('div');

            ddiv.innerHTML = data;

            var d = ddiv.querySelectorAll('fieldset fieldset table');
            var legend = ddiv.querySelectorAll('fieldset fieldset legend');
            var qres=[];
            var forExcel = [];

            for(var i=0;i<d.length;i++){
                //legendler baslik olarak kullanilacak emre
                if(typeof legend[i] != "undefined"){
                    qres = qres.concat([
                        {
                            baslik:legend[i].innerText,
                            icerik:false,
                            legend:true
                        }
                    ]);
                }
                qres = qres.concat(uyapParser.icerikAl(d[i].outerHTML));//fieldleri tektek yolluyoruz
                forExcel = forExcel.concat(uyapParser.icerikAl(d[i].outerHTML));
                //console.log('field',qres);
            }
            //console.log("forExcel",forExcel);
            //console.log("forSHOW",qres);
            return {show:qres,excel:forExcel};
        }

    },
    postaCeki:{
        get:function (data) {
            return uyapParser.gsm.get(data);//duzeltilecek///
        }
    },
    icraDosyasiKaydi:{
        get:function (data) {
            return uyapParser.gsm.get(data);//duzeltilecek///
        }
    },
    gsm:{
        get:function (data) {
            var div = document.createElement("div");
            div.innerHTML = data;
            var message = div.querySelector("div fieldset div p").innerText;
            var table = uyapParser.newTabloParser(div.querySelector("div fieldset div table"));
            return {
                message:message,
                data:table
            };
        }
    },
    gib:{
        get:function (data) {
            var div = uyapParser.div(data);
            var adres =  div.querySelector("div fieldset div p strong").innerHTML.replace(/\<br\>/g,"").replace("Kişiye ait GİB kayıtlarına göre adres  kaydı var.","").trim();
            return {
                adres:adres
            };
        }
    },
    dib:{
        get:function (data) {
            var div = uyapParser.div(data);
            var message = div.querySelector("div fieldset div p").innerText;
            var table = uyapParser.newTabloParser(div.querySelector("div fieldset div table"));
            return {
                message:message,
                data:table
            };
        }
    },
    iski:{
        get:function (data) {
            return uyapParser.dib.get(data);
        }
    },
    sgkHaciz:{
        get:function (data) {
            var parse = uyapParser.gib.get(data);
            return {message:parse.adres};
        }
    },
    takbis:{
        get:function (data) {
            return uyapParser.sgkHaciz.get(data);//ayni parser..
        }
    },
    sgk:{
        get:function (data) {
            var sgkIcerik = uyapParser.icerikAl(data);
            if(sgkIcerik.length == 0)
                sgkIcerik = [{baslik:"",icerik:"KAYIT BULUNAMADI"}];
            return sgkIcerik;
        }
    },
    bagkur:{
        get:function (data) {
            var sgkIcerik = uyapParser.icerikAl(data);
            if(sgkIcerik.length == 0)
                sgkIcerik = [{baslik:"",icerik:"KAYIT BULUNAMADI"}];
            return sgkIcerik;
        }
    },
    isyeri:{
        get:function (data) {
            return uyapParser.isyeriParse(data);
        }
    },
    egm:{
        get:function (data) {
            var parse = uyapParser.gib.get(data);
            return {message:parse.adres};
        },
        oldGet:function (data) {

            var ddiv = document.createElement('div');

            ddiv.innerHTML = data;

            var newArr=[];

            //veriler fieldset olarak geliyor bu yuzden ilk ikisi baslik oldugundan i 2 den basliyor

            var mytable = ddiv.querySelectorAll('fieldset');

            for (var i = 2; i < mytable.length; i++) {

                var parsed = uyapParser.fullParse(mytable[i]);

                newArr = newArr.concat(parsed.icerik);

            }
            if(typeof parsed == 'undefined'){
                //alert('Girilen kriterlere uygun kayıt yok.');
                return {icerik:newArr};
            }

            return {icerik:newArr,basliklar:parsed.baslik};

        }


    },
    mahrumiyet:{


        //gelen data tr ile basliyor o yuzden table ekliyorum

        //uyapparser.fullparse fonksiyonuna uydurmak icin 3 tr de bir alt alta siralanmasi icin 3 tr de bir table etiketi ekliyorum

        //olusturdugum yeni tabloyu fonksiyona gonderiyorum json objesini alip resolve ediyorum

        get:function (data) {

            var table = document.createElement('table');

            table.innerHTML=data;

            data = table;

            var tr = table.querySelectorAll('tr');

            var div  = document.createElement('div');

            for(var i=1; i < tr.length; i=i+3){
                if(typeof tr[i+1]!='undefined' || typeof tr[i+2]!="undefined")
                    div.innerHTML+='<table>'+tr[i].outerHTML+tr[i+1].outerHTML+tr[i+2].outerHTML+'</table>';
            }

            return uyapParser.fullParse(div);

        }

    },
    safahat:{

        get:function (data) {

            var div = document.createElement('div');

            div.innerHTML = data;

            return uyapParser.normalTabloParse(div);
        }
    },
    evraklar:{
        get:function(data,basliksiz){
            emydata = document.createElement('div');
            var newtable = document.createElement('table');
            emydata.innerHTML = data;
            var baslik,value,d;
            if(!basliksiz)
                baslik = ['Dosya Esas No','Evrak ID','İsim'];
            else
                baslik = ['Evrak ID','İsim'];

            var dosyaBilgisi = emydata.querySelector("#browser .folder").innerHTML;
            var tablo = emydata.querySelectorAll('.folder')[2].parentElement.querySelectorAll('.file[title]');
            // emydata.innerHTML = emydata.querySelectorAll('.folder')[2].nextSibling.querySelectorAll('.file[title]')[0].attributes['title'].value;
            let arr = [];
            for(var i = 0;i<tablo.length;i++){
                let obj = {};
                newtable.innerHTML = tablo[i].attributes['title'].value;
                value = newtable.querySelectorAll('div');
                var evrakid = tablo[i].attributes['evrak_id'].value;
                var isim = tablo[i].innerText;
                obj.evrakId = evrakid.trim();
                obj.isim = isim.trim();
                for(var j = 0; j < value.length; j++){
                    d = value[j].innerText.split(':');
                    obj[uyapParser.baslikParse(d[0].trim())]=d[1].trim();
                    if(i==0){
                        baslik.push(d[0].trim());
                    }
                }
                arr.push(obj);
            }
            return {data:arr,basliklar:baslik,dosyaBilgisi:dosyaBilgisi};
        }
    },

    newTabloParser:function (data) {
        if(typeof data != "undefined" || data != null || data != undefined){
            var th = data.querySelectorAll('thead th');

            var basliklar=[];

            var res = [];

            for(var i=0;i<th.length;i++){

                basliklar.push(th[i].innerText.trim());

            }

            var tr = data.querySelectorAll('tbody tr');

            for(var k = 0;k<tr.length;k++){//trler

                var td = tr[k].querySelectorAll('td');

                var obj = {};
                for(var j = 0;j<basliklar.length;j++){//tdler
                    obj[uyapParser.baslikParse(basliklar[j])] = td[j].innerText.trim();
                }

                res.push(obj);
            }

            return {basliklar:basliklar,table:res};
        }
        return [];
    },
    normalTabloParse:function (data) {

        //tablo yapisi adres tel ad soyad faks gibi yan yana icerikler altindaysa..

        var th = data.querySelectorAll('thead th');

        var basliklar=[];

        var arr=[];

        var res = [];

        for(var i=0;i<th.length;i++){

            basliklar.push(th[i].innerText.trim());

        }

        var tr = data.querySelectorAll('tbody tr');

        for(var k = 0;k<tr.length;k++){//trler

            var td = tr[k].querySelectorAll('td');


            for(var j = 0;j<basliklar.length;j++){//tdler

                //console.log(basliklar[j]);
                arr.push({baslik:basliklar[j],icerik:td[j].innerText.trim()});//baslik:baslik icerik :icerik
            }

            //console.log("=======");

            res.push({data:arr});

            arr=[];

        }

        return res;

    },

    /////////////////////// ========== modul fonksiyonlar

    tabloAl: function(data){

        var baslangic = data.indexOf('<table');
        var bitis = data.indexOf('</table>');
        //table tag  aliyorum --emre
        //console.log('tablo',data.substr(baslangic,bitis-baslangic+8));
        return data.substr(baslangic,bitis-baslangic+8);

    },
    domTabloAl:function (data,domsira) {

        var div = document.createElement('div');

        div.innerHTML = data;

        var qdiv = div.querySelectorAll('table');

        return qdiv[domsira];

    },
    fullParse:function (data) {

        data = uyapParser.trTemizle(data);

        parseldata =data;

        baslik = [];

        var table = data.querySelectorAll('table tbody');

        mydata = '[';

        for (var h = 0 ; h < table.length; h++){

            var tr = data.querySelectorAll('table tbody')[h].childNodes;

            mydata+='{';

            for(var i = 0; i < tr.length; i++) {

                var td = tr[i].childNodes;


                //mydata+='{';
                //2 3 4 satirliysa
                for(var j = 0; j < td.length; j++){


                    //burada
                    if(j%2==0){

                        if(td[j].innerText!=''){

                            if(h==0)
                            {
                                baslik.push(td[j].innerText);
                            }

                            mydata+='"'+uyapParser.baslikParse(td[j].innerText)+'" :';

                        }

                    }else{

                        if(td[j-1].innerText!=''){

                            mydata+= '"'+td[j].innerText+'",';

                        }

                    }
                    //burada


                }

                //mydata+='}';


            }

            mydata+='}';

            if(h !== table.length-1){
                //sona virgul koymasin diye
                mydata+=',';
            }

        }

        mydata+=']';
        // ,} olanlari } ile replace ediyor
        mydata=mydata.replace(/,}/g,'}');

        //console.log(mydata);
        ////////////// json parseee
        mydata=JSON.parse(mydata);

        //console.log(mydata);

        return {icerik:mydata,baslik:baslik};

    },
    trTemizle:function(data){

        //bu fonksiyon icinde basliklari silip return data donduruyoruz
        //ornek tr td baslik ' da tr yi siler tr td td td de silmez

        var table = data.querySelectorAll('table tbody');

        for(var h = 0; h< table.length; h++){

            var tr = data.querySelectorAll('table tbody')[h].childNodes;

            for(var i = 0; i < tr.length; i++) {

                var td = tr[i].childNodes;


                if(td.length<2){

                    //console.log(td);

                    data.querySelectorAll('table tbody')[h].childNodes[i].outerHTML='';

                }

            }

        }

        return data;

    },

    isyeriParse:function (data) {
        data = uyapParser.tabloAl(data);

        if(data == "<table  class='table table-bordered table-striped'><td>Kayıt Bulunamadı.</td></table>"){
            return {basliklar:[],data:[],message:'Kayıt Bulunamadı'};
        }
        var div = document.createElement('div');
        div.innerHTML = data;
        var trler = div.querySelectorAll('table tr');
        var json = {};
        var arr = [];
        var jsonIsmi ='';
        var basliklar = {};
        var baslikArr = [];
        trler.forEach(function (tr,i) {
            var tdler = tr.childNodes;
            if(tdler.length == 1){
                if(typeof json[jsonIsmi] != "undefined"){
                    arr.push(json);
                    baslikArr.push(basliklar);
                }
                jsonIsmi = uyapParser.baslikParse(tdler[0].innerText);
                json[jsonIsmi]={};
                basliklar[jsonIsmi]=[];
            }else{
                for (var j = 0 ; j < tdler.length;j=j+2){
                    if(uyapParser.baslikParse(tdler[j].innerText) != ""){
                        json[jsonIsmi][uyapParser.baslikParse(tdler[j].innerText)]=tdler[j+1].innerText;
                        basliklar[jsonIsmi].push(tdler[j].innerText);
                    }
                }
            }
        });

        return ({data:arr,basliklar:baslikArr[0]});
    },

    baslikAl:function (data) { //eskiden isyerinde kullaniliyordu suan pasif  kullanilmiyor emre//

        data = uyapParser.tabloAl(data);

        if(data == "<table  class='table table-bordered table-striped'><td>Kayıt Bulunamadı.</td></table>"){
            return {basliklar:[],data:[],message:'Kayıt Bulunamadı'};
        }
        var div = document.createElement('div');

        div.innerHTML = data;

        tr = div.querySelectorAll('table tr');

        var onBaslik='';
        var mydata ='';
        var basliklar = [];
        //mydata={data:[],basliklar:[]};

        var k = 0;

        //mydata.data[k]=[];

        for(var i = 0; i < tr.length ; i++){

            var td = tr[i].childNodes;

            //tablo baslik ise tek tr li ise

            if(td.length==1){



                if(i==0){

                    var baslik= td[0].innerText;

                    onBaslik = uyapParser.baslikParse(baslik);

                    basliklar[onBaslik]=["Dosya Id","Kişi Kurum ID"];

                    mydata='{"data":[{"'+onBaslik+'":{';

                    //mydata.data[k].push(onBaslik);

                    //mydata.data[k][onBaslik]=[];



                }else{

                    if(uyapParser.baslikParse(td[0].innerText) != onBaslik){

                        onBaslik = uyapParser.baslikParse(td[0].innerText);

                        basliklar[onBaslik]=["Dosya Id","Kişi Kurum ID"];

                        //mydata.data[k][onBaslik]=[];

                        mydata += '},"'+onBaslik+'":[{';


                    }else{

                        var baslikKontrol = true;

                        k++;//ic kismin array i

                        //mydata.data[k][onBaslik]=[];

                        //mydata.data[k]=[];

                        mydata += '},{';

                    }

                }


            }else{


                for (var j = 0;j<td.length;j++){

                    if(j%2==0){

                        if(td[j].innerText!=''){

                            mydata+='"'+uyapParser.baslikParse(td[j].innerText)+'" :';

                            if(!baslikKontrol){
                                basliklar[onBaslik].push(td[j].innerText);
                            }

                        }

                    }else{

                        if(td[j-1].innerText!=''){

                            //mydata.data[k][onBaslik].push(td[j].innerText);

                            mydata+= '"'+td[j].innerText+'",';

                        }

                    }


                }

            }

        }

        mydata += '}]}]}';
        mydata=mydata.replace(/,}/g,'}');
        //onsole.log(mydata);
        ////////////// json parseee

        stringJson = mydata;
        mydata=JSON.parse(mydata);

        mydata.basliklar=basliklar;


        //console.log(mydata);

        return mydata;

    },
    baslikParse:function(text){

        text.replace("-","");
        text.replace(/\(/,'').replace(/\)/,'');
        text = trToEn(text);

        var i=1;
        var strq='';
        while(i == 1){

            var a = text.indexOf(' ');
            //console.log(strq);
            if(a>0){
                var b = text.substr(a+1,1);

                //console.log('selse'+b);
                strq=strq+text.substr(0,a)+b.toUpperCase();
                text=text.substr(a+2,text.length);

            }else{
                var newstr = strq+text;
                //console.log(newstr);
                i=0;
            }

        }



        slugify = function(text) {
            var trMap = {
                'çÇ':'C',
                'ğĞ':'G',
                'şŞ':'S',
                'üÜ':'U',
                'ıİ':'I',
                'öÖ':'O'
            };
            for(var key in trMap) {
                text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
            }
            return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
                .replace(/\s/gi, "-") // convert spaces to dashes
                .replace(/[-]+/gi, "-") // trim repeated dashes
        };


        return slugify(newstr);

    },
    icerikAl:function (data) {

        //data = uyapParser.tabloAl(data);
        var div = document.createElement('div');
        div.innerHTML = data;
        var xarr=[];
        var basliklar=[];
        var tr = div.querySelectorAll('table tbody tr');

        for(var i = 0; i < tr.length ; i++){
            var td = tr[i].childNodes;

            for(var j = 0; j < td.length; j++){

                if(j%2==0){

                    if(typeof td[j+1] !='undefined'){//tek satirli baslik olanlar i almasin

                        if(td[j].innerText!=''){//baslik bos degilse
                            xarr.push({baslik:td[j].innerText,icerik:td[j+1].innerText.trim()});
                        }
                        // baslik:baslik icerik:icerik seklinde /////yaptim///
                    }
                }
            }
        }
        //basliklar=["Dosya ID","Kişi Kurum ID"].concat(basliklar);

        //icerikAl ={basliklar:basliklar,data:xarr};
        return xarr;
    },
    tabloIcerikAl :function (data) {

        //data = uyapParser.domTabloAl(data);

        div = document.createElement('div');

        div.innerHTML = data;

        warr=[];

        var tr = div.querySelector('tbody').childNodes;

        for(var i = 0; i < tr.length ; i++){

            var td = tr[i].childNodes;
            //console.log(td);
            warr[i]=[];
            //warr arrayinin i degerini olusturup icine degerler pushlaniyor --emre
            for(var j = 0; j < td.length; j++){

                warr[i].push(td[j]);

            }
        }

        return  warr;
    },
    borcluAl:function (data) {

        div = document.createElement('div');

        div.innerHTML = data;

        var thisArr=[];

        var tr = div.querySelectorAll('tr');

        for(var i = 0; i < tr.length ; i++){


            var td = tr[i].childNodes;

            thisArr.push({baslik:td[0].innerText.trim(),icerik:td[1].innerText.trim()});

            if(td.length>2){
                thisArr.push({baslik:td[2].innerText.trim(),icerik:td[3].innerText.trim()});
            }

        }


        return  thisArr;

    }


};
module.exports = uyapParser;
