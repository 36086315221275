import axios, {AxiosError, AxiosResponse, Method} from "axios";
import Axios from "axios";
const queryString = require ("querystring");

const X2JS = require("../XMLJS");
const UyapHtmlParser = require("../UyapHtmlParser");

export function isArray(data: any) {
    return Array.isArray(data);
}

export function isUndefined(item: any) {
    return typeof item == "undefined";
}

export abstract class   UyapClient<T, C> {

    axios = axios;
    ImzaClientAddr: string = "http://127.0.0.1:4723/";
    htmlParser = UyapHtmlParser;
    message!: string;

    execute(data: T): Promise<AxiosResponse> {
        if (
            window &&
            window.location &&
            window.location.host.endsWith(".com") &&
            !window.location.host.startsWith("test") &&
            !window.location.host.startsWith("dev")
            // || true
        ) {

            //let params =  new URLSearchParams(data)
            return this.axios({
                method: this.getMethod(),
                url: this.getUrl(),
                data: queryString.stringify(data),
                headers: {
                    "zookie": "JSESSIONID=0000Say0hve3UknBteFAizqT4Xt:1e3ae09ie",
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            });
        } else {
            return new Promise<AxiosResponse>((resolve, reject) => {
                return resolve(<AxiosResponse>{
                    statusText: "OK",
                    status: 200,
                    data: this.getMockResponse(),
                });
            })
        }
    };


    public run(data: T): Promise<C> {
        return new Promise<C>((resolve, reject) => {
            this.execute(data).then((response: AxiosResponse) => {

                this.parse(response.data).then((result: C) => {
                    resolve(result);
                }).catch((err: Error) => {
                    reject(err);
                })
            }).catch((err: AxiosError) => {
                reject({message: err.message});
            });
        })
    }

    abstract getUrl(): string;

    abstract getMockResponse(): string | object | any;

    abstract getMethod(): Method;

    abstract parse(data: string): Promise<C>;


    xml2json (data:string) {
        var x2js = new X2JS();
        return x2js.xml_str2json(data);
    };

}



